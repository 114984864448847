<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">进销存</el-breadcrumb-item>
        <el-breadcrumb-item>库存明细记录</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-form label-width="90px" :model="querys" ref="querys" class="search-form">
      <el-row>
        <el-form-item label="关键词" prop="keyword">
          <el-col style="width: 220px">
            <el-input v-model="querys.keyword" @keyup.enter="fetchs" prefix-icon="Search"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-col>
            <!-- <el-switch v-model="storeSwitch" active-text="在库" inactive-text="出库"></el-switch> -->
            <el-select v-model="status" clearable placeholder="请选择" @change="fetchs()" style="width: 140px">
              <el-option key="1" label="在库货品" value="1"></el-option>
              <el-option key="0" label="出库记录" value="0"></el-option>
              <el-option key="2" label="入库记录" value="2"></el-option>
            </el-select>
          </el-col>
        </el-form-item>

        <el-form-item label="单据时间" label-width="140px">
          <el-col>
            <el-date-picker v-model="dateRange" type="daterange" start-placeholder="开始时间" end-placeholder="结束时间"
              unlink-panels @change="fetchs()" :shortcuts="$util.getShortCuts()"> </el-date-picker>
          </el-col>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="系统款号" prop="detailCode">
          <el-col style="width: 220px">
            <el-input v-model="detailCode" @keyup.enter="fetchs"></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="批次号" prop="serialCode">
          <el-col>
            <el-input v-model="serialCode" @keyup.enter="fetchs" style="width: 140px"></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="品牌批次号" prop="brandSerialCode" label-width="140px">
          <el-col>
            <el-input v-model="brandSerialCode" @keyup.enter="fetchs" style="width: 140px"></el-input>
          </el-col>
        </el-form-item>

      </el-row>
      <el-row>
        <el-form-item label="所属公司">
          <el-col>
            <!-- <el-cascader v-model="companyId" :options="companys" style="width: 220px" clearable @change="fetchs()" /> -->
            <el-tree-select ref="companys" v-model="companyId" :data="companys" @change="fetchs();" clearable
              check-strictly :render-after-expand="false" style="min-width: 220px;" />
          </el-col>
        </el-form-item>
        <el-form-item label="所在库房">
          <el-col>
            <el-select v-model="storeCode" placeholder="库房" clearable @change="fetchs()" style="width: 140px">
              <el-option v-for="item in dict.stores" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="所在渠道" label-width="140px">
          <el-col>
            <el-select v-model="channel" placeholder="渠道" clearable @change="fetchs()" style="width: 140px">
              <el-option v-for="item in dict.channels" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
        </el-form-item>

        <el-form-item label="" label-width="60px">
          <el-button type="primary" icon="search" @click="fetchs()">搜索</el-button>
        </el-form-item>
      </el-row>
    </el-form>

    <div class="toolbar">
      <el-button type="primary" icon="download" @click="exportEvent()">明细导出</el-button>
      <el-button type="primary" icon="download" @click="exportStoreEvent()">在库导出</el-button>
      <el-button plain type="info" icon="upload" @click="boundModify()">批量修改货品信息</el-button>
    </div>

    <static-table class="table" :pager="pager" :columns="querys.columns" :currHandler="currHandler"
      :sortHandler="sortHandler" :pageHandler="pageHandler" :sizeHandler="sizeHandler">

      <template v-slot:billType="scope">
        <el-tag v-if="scope.row.stockStatus === 'available' && scope.row.stockRemain > 0"> 在库 </el-tag>
        <el-tag v-if="scope.row.stockNumber > 0 && scope.row.stockRemain == 0" type="info"> 已出 </el-tag>
        <el-tag v-if="scope.row.stockNumber < 0 && scope.row.stockRemain == 0" type="info"> 出库 </el-tag>
        {{ scope.row.billType }}
      </template>

      <template v-slot:billCode="scope">
        <el-link type="success" size="small" :underline="false" @click="openBillPage(scope.row)">{{ scope.row.billCode
          }}</el-link>
      </template>

      <template v-slot:detailImg="scope">
        <el-image style="height: 32px; width: 32px" lazy :src="apiUrl + 'static/code/' + scope.row.detailCode + '?t=0'"
          :preview-src-list="[apiUrl + 'static/code/' + scope.row.detailCode + '?t=1']">
          <template #error><img style="width: 100%" src="~@/assets/imgunfine.jpg" alt="" /></template>
        </el-image>
      </template>

      <template v-slot:detailCode="scope">
        <el-link type="success" size="small" :underline="false" :href="'?detailCode=' + scope.row.detailCode">{{
          scope.row.detailCode }}</el-link>
      </template>

      <template v-slot:serialCode="scope">
        <!-- <el-link type="success" size="small" :underline="false" :href="'?serialCode='+scope.row.serialCode">{{scope.row.serialCode}}</el-link> -->
        {{ scope.row.serialCode || '' }}
      </template>

      <template v-slot:brandSerialCode="scope">
        <!-- <el-link type="success" size="small" :underline="false" :href="'?brandSerialCode='+scope.row.brandSerialCode">{{scope.row.brandSerialCode}}</el-link> -->
        {{ scope.row.brandSerialCode || '' }}
      </template>

      <template v-slot:toolbar="scope">
        <el-button @click="edit(scope.row)" link size="small">[编辑]</el-button>
      </template>
    </static-table>

    <static-dialog ref="dialog" class="dialog" title="货品信息维护" :ok="submit">
      <el-form :model="forms" :rules="rules" ref="forms" :label-width="labelWidth">
        <el-row :gutter="10">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="单据类型" prop="billType" style="max-width: 80%">
              <el-input v-model="forms.billType" autocomplete="off" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="单据编号" prop="billCode" style="max-width: 80%">
              <el-input v-model="forms.billCode" autocomplete="off" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="系统款号" prop="detailCode" style="max-width: 80%">
              <el-input v-model="forms.detailCode" autocomplete="off" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="货品名称" prop="detailName">
              <el-input v-model="forms.detailName" autocomplete="off" maxlength="30" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="库房" prop="storeCode" style="max-width: 80%">
              <el-input v-model="forms.storeCode" autocomplete="off" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="渠道" prop="channel">
              <el-input v-model="forms.channel" autocomplete="off" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="品牌名称" prop="brandCode">
              <el-input v-model="forms.brandCode" autocomplete="off" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="品牌款号" prop="brandModelCode" style="max-width: 80%">
              <el-input v-model="forms.brandModelCode" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="款式工厂" prop="factoryCode">
              <el-input v-model="forms.factoryCode" autocomplete="off" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="工厂款号" prop="factoryModelCode" style="max-width: 80%">
              <el-input v-model="forms.factoryModelCode" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="系统批次号" prop="serialCode" style="max-width: 80%">
              <el-input v-model="forms.serialCode" autocomplete="off" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="品牌货号" prop="brandSerialCode" style="max-width: 80%">
              <el-input v-model="forms.brandSerialCode" autocomplete="off" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="总质量" prop="weight" style="max-width: 70%">
              <el-input v-model="forms.weight" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="规格" prop="weight" style="max-width: 70%">
              <el-input v-model="forms.unitName" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="证书号" prop="certNumber" style="max-width: 70%">
              <el-input v-model="forms.certNumber" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">

          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="采购价" prop="purchasePrice" style="max-width: 70%">
              <el-input type="number" v-model="forms.purchasePrice" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="品牌使用费" prop="useBrandPrice" style="max-width: 70%">
              <el-input type="number" v-model="forms.useBrandPrice" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="入库价" prop="productPrice" style="max-width: 70%">
              <el-input type="number" v-model="forms.productPrice" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="计划售价" prop="sellPrice" style="max-width: 70%">
              <el-input v-model="forms.sellPrice" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </static-dialog>

    <static-dialog ref="boundModifyDialog" class="dialog" title="批量修改货品信息" :okVisible="false">
      <boundmodifypage></boundmodifypage>
    </static-dialog>
  </el-card>
</template>

<script>
import boundmodifypage from "@/views/stock/bound/upload.vue";
import { fetchBounds, fetchBound, submitBound, exportStoreBound, boundModify } from "@/api/stock";
import StaticDialog from "@/components/static-dialog.vue";
import StaticTable from "@/components/static-table.vue";
export default {
  name: "Bound",
  components: { StaticDialog, StaticTable, boundmodifypage },
  data() {
    return {
      apiUrl: "",
      dateRange: "",
      detailCode: "",
      serialCode: "",
      brandSerialCode: "",
      status: '1',

      querys: {
        keyword: "",
        filters: [],
        columns: [
          { type: "index", label: "序号" },
          { type: "template", prop: "billType", label: "状态", width: "130", searchable: true, showOverflowTooltip: true, },
          { prop: "storeCode", label: "库房", sortable: true, searchable: true, width: "70", showOverflowTooltip: true, },
          // { prop: "billCode", label: "单据编号", width: "130", sortable: true, searchable: true, },
          { type: "template", prop: "billCode", label: "单据编号", width: "130", searchable: true, showOverflowTooltip: true, },
          { prop: "channel", label: "渠道", sortable: true, searchable: true, width: "70", showOverflowTooltip: true, },
          { type: "template", prop: "detailCode", label: "款号", sortable: true, searchable: true, showOverflowTooltip: true, width: "125", },
          { prop: "brandModelCode", label: "品牌款号", sortable: true, searchable: true, width: "120", showOverflowTooltip: true, },
          { prop: "detailName", label: "名称", sortable: true, searchable: true, width: "170", showOverflowTooltip: true },
          { type: "template", prop: "detailImg", label: "图片", width: "50", },
          { type: "template", prop: "serialCode", label: "批次号", sortable: true, searchable: true, showOverflowTooltip: true, width: "120", },
          { prop: "weight", label: "金重", width: "60", showOverflowTooltip: true, },
          { type: "template", prop: "brandSerialCode", label: "品牌批次号", sortable: true, searchable: true, showOverflowTooltip: true, width: "120", },
          { prop: "certNumber", label: "证书号", sortable: true, searchable: true, showOverflowTooltip: true, width: "130", },
          { prop: "unitName", label: "规格", width: "70", showOverflowTooltip: true, },

          { prop: "purchasePrice", label: "采购价", sortable: true, searchable: true, showOverflowTooltip: true, width: "90", },
          { prop: "productPrice", label: "入库价", sortable: true, searchable: true, showOverflowTooltip: true, width: "90", },
          { prop: "labelPrice", label: "标签价", sortable: true, searchable: true, showOverflowTooltip: true, width: "90", },
          { prop: "sellPrice", label: "计划售价", sortable: true, searchable: true, showOverflowTooltip: true, width: "90", },

          { type: "visible", prop: "stockStatus", label: "在库?", },
          { prop: "stockNumber", label: "操作数", width: "70", },
          { prop: "stockRemain", label: "剩余数", sortable: true, width: "80", showOverflowTooltip: true, },
          { prop: "stockDate", label: "单据时间", sortable: true, width: "140", showOverflowTooltip: true, },
          { prop: "factoryCode", label: "供应商", sortable: true, searchable: true, showOverflowTooltip: true, width: "80", },
          { prop: "platformName", label: "平台名称", searchable: true, showOverflowTooltip: true, width: "80", },
          { prop: "platformOrderNo", label: "平台单号", sortable: true, searchable: true, showOverflowTooltip: true, width: "160", },
          { prop: "receiverName", label: "收货人", sortable: true, searchable: true, width: "90", showOverflowTooltip: true },
          { prop: "receiverMobile", label: "收货手机号", sortable: true, searchable: true, width: "120", showOverflowTooltip: true },
          { prop: "outOrderNo", label: "原单号", sortable: true, searchable: true, showOverflowTooltip: true, width: "120" },
          { prop: "remark", label: "备注", sortable: true, searchable: true, showOverflowTooltip: true, width: "150" },
          { prop: "stockReason", label: "日志", sortable: true, searchable: true, showOverflowTooltip: true, width: "150" },

          { prop: "createDate", label: "创建时间", sortable: true, width: "140", },
          { prop: "createUserName", label: "创建人", sortable: true, searchable: true, width: "85", },
          { prop: "updateDate", label: "更新时间", sortable: true, width: "140", },
          { prop: "updateUserName", label: "更新人", sortable: true, searchable: true, width: "85", },

          { type: "toolbar", label: "操作" },
        ],
      },
      pager: {
        sidx: "stockDate", //默认的排序字段
        sord: "descending",
      },
      currRow: null,
      centerDialogVisible: false,
      dialogWidth: "30%",

      forms: {},
      labelWidth: "110px",
      rules: {},

      companys: [],
      companyId: '',
      storeCode: null,
      channel: null,
      dict: {
        inBillTypes: [],
        outBillTypes: [],
        stores: [],
        channels: [],
      },
    };
  },
  mounted() {
    this.apiUrl = process.env.VUE_APP_BASEURL;

    this.$kaung.companys().then((res) => {
      this.companys = res;

      this.fetchs();
    });
  },
  methods: {
    edit(row) {
      fetchBound(row.id).then((res) => {
        this.forms = { ...res.data };
        this.$refs.dialog.toggle();
      });
    },
    submit() {
      let that = this;

      this.$refs.forms.validate((valid) => {
        if (!valid) return;

        submitBound(that.forms).then((res) => {
          that.$message({ type: res.type, message: res.content, });

          if (res.data) {
            that.fetchs();
            that.$refs.dialog.toggle();
          }
        });
      });
    },
    fetchs(methodName) {
      if ((typeof methodName) == 'object') methodName = null;
      this.querys.filters = [];
      if (this.dateRange && this.dateRange[0])
        this.querys.filters.push({
          name: "stockDate",
          value: this.$util.fmtDate(this.dateRange[0], "yyyy-MM-dd"),
          operate: 2,
        });
      if (this.dateRange && this.dateRange[1])
        this.querys.filters.push({
          name: "stockDate",
          value: this.$util.fmtDate(this.dateRange[1], "yyyy-MM-dd") + " 23:59:59",
          operate: 3,
        });

      if (this.detailCode)
        this.querys.filters.push({
          name: "detailCode",
          value: "%" + this.detailCode + "%",
          operate: 5,
        });
      if (this.serialCode)
        this.querys.filters.push({
          name: "serialCode",
          value: "%" + this.serialCode + "%",
          operate: 5,
        });
      if (this.brandSerialCode)
        this.querys.filters.push({
          name: "brandSerialCode",
          value: "%" + this.brandSerialCode + "%",
          operate: 5,
        });
      //在库
      if ((this.status || "") === "1") {
        this.querys.filters.push({
          name: "stockRemain",
          value: "0",
          operate: 1,
        });
        this.querys.filters.push({
          name: "stockStatus",
          value: "available",
          operate: 0,
        });
      }
      //入库记录
      if ((this.status || "") === "2") {
        this.querys.filters.push({
          name: "stockNumber",
          value: "0",
          operate: 1,
        });
      }
      //出库
      if ((this.status || "") === "0") {
        this.querys.filters.push({
          //name: "stockRemain",
          name: "stockNumber",
          value: "0",
          operate: 3,
        });
      }
      //所属公司
      if (this.companyId && this.companyId.length > 0) {
        this.querys.filters.push({
          name: "companyId",
          value: this.companyId,
          operate: 6,
        });
      }
      //库房
      if (this.storeCode)
        this.querys.filters.push({
          name: "storeCode",
          value: this.storeCode,
          operate: 0,
        });
      if (this.channel)
        this.querys.filters.push({
          name: "channel",
          value: this.channel,
          operate: 0,
        });

      let { data, records, total, ...search } = {
        ...this.pager,
        ...this.querys,
      };
      search.method = methodName || '';
      fetchBounds(search).then((res) => {
        if (search.method == 'Export') {
          this.$notify({
            title: "导出成功",
            message: res.content,
            type: "success",
            duration: 10000
          });
          window.location.href = process.env.VUE_APP_BASEURL + res.data;
        }
        else {
          this.pager = { ...res.data };
        }
      });
    },
    currHandler(row) {
      this.currRow = row;
    },
    pageHandler(page) {
      this.pager.page = page;
      this.fetchs();
    },
    sizeHandler(rows) {
      this.pager.rows = rows;
      this.fetchs();
    },
    sortHandler(order) {
      this.pager.sidx = order.prop;
      this.pager.sord = order.order;
      this.fetchs();
    },
    //控制dialog宽度与子组件控制关闭弹窗
    getdialogWidth(data) {
      if (data === "80%" || data === "30%") {
        this.dialogWidth = data;
      } else {
        this.centerDialogVisible = false;
      }
    },

    exportEvent() {
      this.fetchs('Export');
    },
    exportStoreEvent() {
      exportStoreBound();
    },
    boundModify() {
      this.$refs.boundModifyDialog.toggle();
    },
    openBillPage(bill) {
      let inOptions = this.dict.inBillTypes.filter(function (item) {
        return item.name == bill.billType;
      });
      if (inOptions && inOptions.length > 0) {
        this.$router.push("/stock/bills/indetail?billCode=" + bill.billCode);
      } else {
        this.$router.push("/stock/bills/detail?billCode=" + bill.billCode);
      }
    }
  },
  watch: {
    companyId: function (newValue, oldValue) {
      if (newValue && newValue != oldValue)
        this.$util.setLocalstorage('companyIdStr', newValue);
      else
        this.$util.setLocalstorage('companyIdStr', '');
    },
    pager: function (newValue, oldValue) {
      this.$util.setPageCache('keyword', newValue.keyword);
      if (newValue.page)
        this.$util.setPageCache('page', newValue.page);
      if (newValue.rows)
        this.$util.setPageCache('rows', newValue.rows);
      if (newValue.sidx)
        this.$util.setPageCache('sidx', newValue.sidx);
      if (newValue.sord)
        this.$util.setPageCache('sord', newValue.sord);
    },
    status: function (newValue, oldValue) {
      if (newValue != oldValue)
        this.$util.setPageCache('status', newValue);
    },
    storeCode: function (newValue, oldValue) {
      if (newValue != oldValue)
        this.$util.setPageCache('storeCode', newValue);
    },
  },
  created: function () {
    this.dateRange = this.$util.getCurrentDate();

    this.$kaung.dictionary("stock", "inbound").then(res => this.dict.inBillTypes = res);
    this.$kaung.dictionary("stock", "outbound").then(res => this.dict.outBillTypes = res);
    this.$kaung.dictionary("stock", "store").then(res => this.dict.stores = res);
    this.$kaung.dictionary("stock", "channel").then(res => this.dict.channels = res);

    this.detailCode = this.$route.query.detailCode || '';
    this.serialCode = this.$route.query.serialCode || '';
    this.brandSerialCode = this.$route.query.brandSerialCode || '';

    this.companyId = this.$util.getLocalstorage('companyIdStr');

    this.querys.keyword = this.$util.getPageCache('keyword');

    this.pager.page = parseInt(this.$util.getPageCache('page') || 1);
    this.pager.rows = parseInt(this.$util.getPageCache('rows') || 10);

    let sidx = this.$util.getPageCache('sidx');
    if (sidx) this.pager.sidx = sidx;
    let sord = this.$util.getPageCache('sord');
    if (sord) this.pager.sord = sord;

    let storeCode = this.$util.getPageCache('storeCode');
    if (storeCode) this.storeCode = storeCode;
  }
};
</script>

<style lang="less">
.xlsdialog {
  .el-dialog__body {
    max-height: 80vh;
    overflow-y: auto;
  }
}

.el-form .el-date-editor {
  width: 220px;
}

.el-link {
  font-size: 0.8em;
}
</style>>
